import { createContext } from 'react';

export interface IUser {
    name: string
    unique_name: string
}

export interface ICurrentUser {
    isLogged: boolean
    loading: boolean
    user: IUser | null
}

export const CurrentUserContext = createContext<ICurrentUser | null>(null);