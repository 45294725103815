import React, { useEffect, useState } from 'react';
import { SWRConfig } from 'swr';
import Notifications from 'components/Notifications';
import Routes from 'routes';
import { GlobalStyles } from 'styles/theme';
import Loader from './components/Loader';
import { ICurrentUser, CurrentUserContext, IUser } from 'utils/userContext';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { addNotification } from 'utils/notifications';

/*function ProfileContent() {

    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    const name = accounts[0] && accounts[0].name;

    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${response.accessToken}`
                }
            };
            
            fetch('https://radon-pam.azurewebsites.net/users/80e659cb-1aae-43ff-a2c7-03a29e1a37cc/make-admin', requestOptions)
                .then((response) => response.json())
                .then((response) => console.log(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                /!*callMsGraph(response.accessToken).then(response => setGraphData(response));*!/
            });
        });
    }

    return (
        <>
            <h5 className="card-title">Welcome {name}</h5>
            {graphData ?
                <ProfileData graphData={graphData} />
                :
                <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
            }
        </>
    );
};*/

const App = () => {
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({ isLogged: false, loading: true, user: null});
  const [notificationHubConnection, setNotificationHubConnection] = useState<HubConnection | undefined>(undefined);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/users/claims`, { credentials: 'include' })
      .then(res => {
        if(res.ok) {
          const connection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_PUBLIC_API_URL}/hubs/notifications`)
          .build();

          connection.on('Notify', ({text, type }) => {
            addNotification(text, type);
          });
          connection.start();

          setNotificationHubConnection(connection);

          return res.json().then((userData) => {
            const poll = () => {
              fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/poll`, { credentials: 'include' })
                .then(res => {
                  if (!res.ok) {
                    setCurrentUser({...currentUser, isLogged: false, loading: false});
                    if (notificationHubConnection && (notificationHubConnection.state === HubConnectionState.Connected || notificationHubConnection.state === HubConnectionState.Connecting)) {
                      notificationHubConnection.stop();
                    }
                  } 
                });
            };

            const user: IUser = {} as IUser;
            if (userData?.length) {
              userData.forEach((key: { key: keyof IUser; value: string; }) => {
                user[key.key] = key.value;
              });
            }

            setCurrentUser({ ...currentUser, isLogged: true, loading: false, user });
            const intervalTime = 5000;
            setInterval(poll, intervalTime);
          });
        }

        setCurrentUser({ ...currentUser, isLogged: false, loading: false});
      });
  }, []);

  if (currentUser.loading) {
    return <Loader />;
  }

  return (
    <>
      <GlobalStyles />
      <Notifications />
        <SWRConfig value={{ shouldRetryOnError: false, dedupingInterval: 20000 }}>
          {/*<AppContext.Provider value={context}>*/}
          <CurrentUserContext.Provider value = { currentUser }>
            <Routes />
          </CurrentUserContext.Provider>
          {/*</AppContext.Provider>*/}
        </SWRConfig>
        {/*<Loader />*/}
    </>
  );
};

export default App;
