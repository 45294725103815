import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { CurrentUserContext } from 'utils/userContext';

interface Props extends RouteProps {
  type?: 'private' | 'public';
}

const CustomRoute = (props: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const { type } = props;

  if (type === 'private' && !currentUser?.isLogged) {
    return <Redirect to="/login" />;
  }

  if (type === 'public' && currentUser?.isLogged) {
    return <Redirect to="/" />;
  }

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Route {...props} />
  );
};

export default CustomRoute;
