import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import crossBlack from 'assets/images/cross_black.svg';

import { NotificationItem, NotificationType } from 'utils/types';
import { removeNotification } from 'utils/notifications';
import { usePageVisibility, useNotifications } from 'utils/hooks';

import Portal from 'components/Portal';

import theme from 'styles/theme';

interface Props {
  item: NotificationItem;
}

interface StyledItemContainerProps {
  closed: boolean;
  type: NotificationType;
}

const StyledItemContainer = styled.div<StyledItemContainerProps>`
  position: relative;
  width: 100%;
  padding: 16px 32px 16px 16px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  transform: translateX(100%);
  animation: ${(props) =>
    props.closed
      ? 'animate-out 0.6s ease-in-out;'
      : 'animate-in 0.3s ease-in-out'};
  animation-fill-mode: forwards;
  overflow-y: hidden;
  display: inline-block;
  margin: 8px 0;
  background-color: ${(props) => {
    switch (props.type) {
      case 'error':
        return theme.colors.red;
      case 'warning':
        return theme.colors.yellow;
      case 'success':
        return theme.colors.green;
    }
  }};
  box-shadow: 0 40px 50px -20px rgba(50, 60, 76, 0.15);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  button {
    position: absolute;
    top: 12px;
    right: 12px;

    img {
      width: 12px;
      height: 12px;
    }
  }

  @keyframes animate-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes animate-out {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(100%);
      max-height: 88px;
      padding: 16px;
    }

    100% {
      max-height: 0;
      padding: 0;
    }
  }
`;

const Item = ({ item }: Props) => {
  const [closed, setClosed] = useState(false);
  const [hasMouse, setHasMouse] = useState(false);
  const isPageVisible = usePageVisibility();

  const { text, key, type } = item;

  const onClose = useCallback(() => {
    setClosed(true);
    window.setTimeout(() => {
      removeNotification(key);
    }, 600);
  }, [key]);

  const onMouseEnter = useCallback(() => {
    setHasMouse(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHasMouse(false);
  }, []);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (!hasMouse && isPageVisible) {
        onClose();
      }
    }, 5000);

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasMouse, isPageVisible, onClose]);

  return (
    <StyledItemContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      closed={closed}
      type={type}
    >
      <button onClick={onClose} type="button">
        <img src={crossBlack} alt="Close Icon" />
      </button>
      {text}
    </StyledItemContainer>
  );
};

const StyledNotificationsContainer = styled.div`
  position: fixed;
  top: 106px;
  right: 0;
  width: 400px;
  z-index: 99;
`;

const Notifications = () => {
  const notifications = useNotifications();

  const notifcationItems = useMemo(() => {
    if (!notifications || notifications.length === 0) {
      return <></>;
    }

    return notifications.map((item) => <Item key={item.key} item={item} />);
  }, [notifications]);

  return (
    <Portal id="notifications-portal">
      <StyledNotificationsContainer>
        {notifcationItems}
      </StyledNotificationsContainer>
    </Portal>
  );
};

export default Notifications;
