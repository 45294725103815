import { createGlobalStyle } from 'styled-components';

const breakpoints = {
  xSmall: 767,
  small: 1024,
  medium: 1280,
  large: 1920,
};

const theme = {
  breakpoints,
  maxWidth: 1280,
  media: {
    xSmall: `${breakpoints.xSmall}px`,
    small: `${breakpoints.small}px`,
    medium: `${breakpoints.medium}px`,
    large: `${breakpoints.large}px`,
  },
  fontFamilies: {
    heading: "'MaisonNeue-Medium', sans-serif",
    headingMono: "'MaisonNeueMono-Regular'",
    body: "'MaisonNeue-Demi', sans-serif",
  },
  colors: {
    blue: '#0b00ff',
    lightBlue: 'rgb(11, 0, 255, 0.5)',
    black: '#000000',
    white: '#ffffff',
    lightGrey: '#f4f4f4',
    red: '#e42828',
    yellow: '#e3c040',
    green: '#73ada0',
    overlay: 'rgba(244, 244, 244, 0.8)',
  },
};

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    color: ${theme.colors.black};
  }
  //TODO: temporary fix for empty iframe when error occurs
  iframe {
    pointer-events: none;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.fontFamilies.heading};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span {
    font-weight: 500;
    margin: 0;
  }

  b {
    font-family: ${theme.fontFamilies.heading};
    font-weight: 500;
  }

  h1 {
    font-family: ${theme.fontFamilies.heading};
    font-weight: 500;
    font-size: 4.375rem;
    line-height: 5.25rem;
  }

  h2 {
    font-family: ${theme.fontFamilies.heading};
    font-weight: 500;
    font-size: 3.375rem;
    line-height: 4.25rem;
  }

  h3 {
    font-size: 1.3rem;
    color: ${theme.colors.black};
    font-family: ${theme.fontFamilies.heading};
    font-weight: 500;
  }

  h4 {
    font-size: 1.1rem;
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
  }

  h5 {
    font-size: 1rem;
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
  }

  h6 {
    font-size: 14px;
    line-height: 18px;
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
  }

  p,
  span,
  a {
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  div,
  button,
  a {
    box-sizing: border-box;
  }

  a {
    color: ${theme.colors.blue};
    text-decoration: none;
    border-bottom: 1px solid transparent;
    word-wrap: break-word;
  }

  a:hover {
    border-bottom: 1px solid ${theme.colors.blue};
  }

  .max-width-container {
    max-width: ${theme.maxWidth}px;
    padding: 0 7.5rem;
    margin: auto;
  }

  @media only screen and (max-width: ${theme.media.small}) {
    .max-width-container {
      padding: 0 1.25rem;
    }
  }

  @media only screen and (max-width: ${theme.media.xSmall}) {
    .max-width-container {
      padding: 0 5.75rem;
    }
  }

  .error {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 1rem;
    color: #f00;
    font-size: 14px;
    text-align: left;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
    outline: none;
    margin: 0;
  }

  input,
  textarea {
    border: 0;
    padding: 0 0 10px 0;
    display: block;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid ${theme.colors.blue};
    font-size: 14px;
    color: ${theme.colors.blue};
    font-family: ${theme.fontFamilies.body};
    font-weight: 600;
    box-sizing: border-box;
  }

  textarea {
    border: 1px solid ${theme.colors.blue};
    padding: 5px
  }

  input::placeholder,
  textarea::placeholder {
    color: ${theme.colors.blue};
  }

  input:last-child {
    margin-bottom: 0;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default theme;
